import { render, staticRenderFns } from "./PatchesTemplate.vue?vue&type=template&id=926bbac8&scoped=true&"
import script from "./PatchesTemplate.vue?vue&type=script&lang=js&"
export * from "./PatchesTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "926bbac8",
  null
  
)

export default component.exports