<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['patches'])">
      <single-answer-question
        title="label.patches"
        :value="patches"
        :options="$options.responseOptions"
        yes-no
        dysfunction="skin-texture"
        @input="onFieldChange('patches', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="isPatchesTypeVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['patchesType'])"
      >
        <single-answer-question
          id="patches-type"
          title="label.patchesType"
          :value="patchesType"
          :options="$options.patchTypeOptions"
          dysfunction="skin-texture"
          @input="onFieldChange('patchesType', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { PATCH_TYPE } from '@/modules/questionnaire/api/constants';
import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

const PATCH_TYPE_OPTIONS = [
  { value: PATCH_TYPE.MOSTLY_DRY, text: 'patchesType.mostlyDry' },
  { value: PATCH_TYPE.VERY_DRY, text: 'patchesType.veryDry' },
  { value: PATCH_TYPE.SWOLLEN_AND_GREASY, text: 'patchesType.swollenAndGreasy' },
  { value: PATCH_TYPE.UNSURE, text: 'patchesType.unsure' }
];

export default {
  name: 'PatchesTemplate',
  components: { FadeInTransitionWrap, QuestionWithErrorWrap, SingleAnswerQuestion },
  responseOptions: YES_NO_RESPONSE_OPTIONS,
  patchTypeOptions: PATCH_TYPE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    patches: {
      type: String,
      default: ''
    },
    patchesType: {
      type: String,
      default: ''
    },
    isPatchesTypeVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped></style>
